import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/xinliuhe/sdXinLiuHeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { accountAdd } from '../../../../api/apis';
import md5 from 'js-md5';
import { mapState, mapGetters, mapActions } from 'vuex';
import aes from "@/plugin/aes.js";
import util from '../../../../libs/util';
export default {
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  data: function data() {
    var _this = this;

    var valTwo = function valTwo(rule, value, callback) {
      if (value !== _this.form.newPwd) {
        return callback(new Error('两次输入密码不一致'));
      }

      callback();
    };

    var valOld = function valOld(rule, value, callback) {
      // console.log(md5(value) ,_this.info.pwd)
      if (aes.encrypt(value) !== _this.info.pwd) {
        return callback(new Error('原密码输入错误'));
      }

      callback();
    };

    return {
      dialogVisible: false,
      isSelf: false,
      form: {
        newPwd: '',
        oldPwd: '',
        account: '',
        name: '',
        id: '',
        newPwdCopy: '',
        code: ''
      },
      rules: {
        newPwd: [{
          validator: this.$tempModel.rules.pwd3.validator,
          trigger: 'blur'
        }],
        newPwdCopy: [{
          validator: valTwo,
          trigger: 'blur'
        }],
        oldPwd: [{
          validator: valOld,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: _objectSpread({}, mapActions('d2admin/account', ['logout']), {
    show: function show(uid, account, name) {
      var myUid = util.cookies.get('uuid');
      console.log('uid', uid, myUid);

      if (myUid == uid) {
        this.isSelf = true;
      } else {
        this.isSelf = false;
      }

      this.form.id = uid;
      this.form.newPwd = '';
      this.form.oldPwd = '';
      this.form.newPwdCopy = '';
      this.form.account = account;
      this.form.name = name;
      this.dialogVisible = true;
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = {};
          params.id = _this2.form.id;
          params.pwd = aes.encrypt(_this2.form.newPwd);
          params.tcode = _this2.form.code;
          accountAdd(params).then(function _callee(res) {
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    _this2.$message({
                      message: '修改成功',
                      type: 'success'
                    });

                    if (_this2.isSelf) {
                      _this2.$alert('请使用新的密码重新登录', '提示', {
                        confirmButtonText: '确定',
                        callback: function callback(action) {
                          _this2.logout({
                            confirm: false
                          });
                        }
                      });
                    } else {
                      _this2.dialogVisible = false;
                    }

                  case 2:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      });
    }
  })
};