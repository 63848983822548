import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/xinliuhe/sdXinLiuHeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { mapState, mapMutations } from 'vuex';
import hotkeys from 'hotkeys-js';
export default {
  components: {
    'd2-panel-search': function d2PanelSearch() {
      return import('../components/panel-search');
    }
  },
  mounted: function mounted() {
    var _this = this;

    // 绑定搜索功能快捷键 [ 打开 ]
    hotkeys(this.searchHotkey.open, function (event) {
      event.preventDefault();

      _this.searchPanelOpen();
    }); // 绑定搜索功能快捷键 [ 关闭 ]

    hotkeys(this.searchHotkey.close, function (event) {
      event.preventDefault();

      _this.searchPanelClose();
    });
  },
  beforeDestroy: function beforeDestroy() {
    hotkeys.unbind(this.searchHotkey.open);
    hotkeys.unbind(this.searchHotkey.close);
  },
  computed: _objectSpread({}, mapState('d2admin', {
    searchActive: function searchActive(state) {
      return state.search.active;
    },
    searchHotkey: function searchHotkey(state) {
      return state.search.hotkey;
    }
  })),
  methods: _objectSpread({}, mapMutations({
    searchToggle: 'd2admin/search/toggle',
    searchSet: 'd2admin/search/set'
  }), {
    /**
     * 接收点击搜索按钮
     */
    handleSearchClick: function handleSearchClick() {
      var _this2 = this;

      this.searchToggle();

      if (this.searchActive) {
        setTimeout(function () {
          if (_this2.$refs.panelSearch) {
            _this2.$refs.panelSearch.focus();
          }
        }, 500);
      }
    },
    searchPanelOpen: function searchPanelOpen() {
      var _this3 = this;

      if (!this.searchActive) {
        this.searchSet(true);
        setTimeout(function () {
          if (_this3.$refs.panelSearch) {
            _this3.$refs.panelSearch.focus();
          }
        }, 500);
      }
    },
    // 关闭搜索面板
    searchPanelClose: function searchPanelClose() {
      if (this.searchActive) {
        this.searchSet(false);
      }
    }
  })
};