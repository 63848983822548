import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.join";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/xinliuhe/sdXinLiuHeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/Users/suifeng/Desktop/projects/2024/xinliuhe/sdXinLiuHeAdmin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import setting from '@/setting.js';
export default {
  namespaced: true,
  state: {
    // 搜索面板激活状态
    active: false,
    // 快捷键
    hotkey: {
      open: setting.hotkey.search.open,
      close: setting.hotkey.search.close
    },
    // 所有可以搜索的页面
    pool: []
  },
  mutations: {
    /**
     * @description 切换激活状态
     * @param {Object} state state
     */
    toggle: function toggle(state) {
      if (!setting.search.enable) return;
      state.active = !state.active;
    },

    /**
     * @description 设置激活模式
     * @param {Object} state state
     * @param {Boolean} active active
     */
    set: function set(state, active) {
      if (!setting.search.enable) return;
      state.active = active;
    },

    /**
     * @description 初始化
     * @param {Object} state state
     * @param {Array} menu menu
     */
    init: function init(state, menu) {
      var pool = [];

      var push = function push(menu) {
        var titlePrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        menu.forEach(function (m) {
          if (m.children) {
            push(m.children, [].concat(_toConsumableArray(titlePrefix), [m.title]));
          } else {
            pool.push(_objectSpread({}, m, {
              fullTitle: [].concat(_toConsumableArray(titlePrefix), [m.title]).join(' / ')
            }));
          }
        });
      };

      push(menu);
      state.pool = pool;
    }
  }
};