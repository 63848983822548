import { render, staticRenderFns } from "./articleBase.vue?vue&type=template&id=61236859&scoped=true&"
import script from "./articleBase.vue?vue&type=script&lang=js&"
export * from "./articleBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61236859",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/suifeng/Desktop/projects/2024/xinliuhe/sdXinLiuHeAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61236859')) {
      api.createRecord('61236859', component.options)
    } else {
      api.reload('61236859', component.options)
    }
    module.hot.accept("./articleBase.vue?vue&type=template&id=61236859&scoped=true&", function () {
      api.rerender('61236859', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/article/articleBase.vue"
export default component.exports