import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/xinliuhe/sdXinLiuHeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Mock from 'mockjs';
import qs from 'qs';
import withCredentials from './patch/withCredentials';
/* 补丁 */

withCredentials(Mock);
/* Mock 默认配置 */

Mock.setup({
  timeout: '200-300'
});
/* 扩展 [生成器] */

var Generator = function Generator(prop, template) {
  var obj = {};
  obj[prop] = [template];
  return Mock.mock(obj);
};
/* 扩展 [循环] */


var Repeat = function Repeat(num, itemTemplate) {
  return Generator("data|".concat(num), itemTemplate).data;
};

var CustomExtends = {
  Generator: Generator,
  Repeat: Repeat,
  Mock: Mock,
  Random: Mock.Random
};

var extend = function extend(prop, value) {
  CustomExtends[prop] = value;
};
/* 装配配置组 */


var wired = function wired(_ref) {
  var url = _ref.url,
      type = _ref.type,
      body = _ref.body;
  return _objectSpread({
    method: type,
    params: qs.parse(url.split('?').length > 1 ? url.split('?')[1] : ''),
    body: JSON.parse(body),
    url: qs.parse(url.split('?')[0])
  }, CustomExtends);
};

var setup = function setup(path, method, handle) {
  Mock.mock(RegExp(path), method, typeof handle === 'function' ? function (o) {
    return handle(wired(o));
  } : handle);
};

var load = function load(collection) {
  collection.map(function (_ref2) {
    var path = _ref2.path,
        method = _ref2.method,
        handle = _ref2.handle;

    if (method === '*') {
      method = ['get', 'head', 'post', 'put', 'delete', 'connect', 'options', 'trace', 'patch'];
    }

    if (typeof method === 'string' && method.indexOf('|') > -1) method = method.split('|');

    if (method instanceof Array) {
      method.map(function (item) {
        return setup(path, item, handle);
      });
    } else {
      setup(path, method, handle);
    }
  });
};

export default {
  setup: setup,
  load: load,
  extend: extend
};