import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import { configBonusList, refreshReport, systemReportList } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'isCollapse': false,
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }, {
          'icon': 'el-icon-position',
          'tag': 'export',
          'title': '导出',
          'type': 'primary'
        }],
        'searchData': [{
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [],
            'title': '今天',
            'type': 'button'
          }, {
            'field': [],
            'title': '昨天',
            'type': 'button'
          }, {
            'field': [],
            'title': '最近7天',
            'type': 'button'
          }, {
            'field': [],
            'title': '本月',
            'type': 'button'
          }, {
            'field': [],
            'title': '本年',
            'type': 'button'
          }, {
            'field': [],
            'title': '自定义',
            'type': 'custom-datetime-range'
          }],
          'id': 1,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '报表日期',
          'type': 1,
          'remoteFunc': ['modelConditionFunc_1']
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'border': true,
        'del': false,
        'headerButton': [],
        'id': 'id_1001',
        'isGroup': false,
        'isTree': false,
        'rowButton': [{
          'icon': '',
          'id': '',
          'tag': 'default',
          'title': '刷新',
          'type': null
        }],
        'showPagination': true,
        'showSelection': false,
        'showSummary': true,
        'stripe': true,
        'tableAutocompleteProps': {},
        'tableCascaderProps': {},
        'tableFileProps': {},
        'tableModel': [{
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '日期',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'key_date'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '拨比',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': 80,
          'prop': 'bobi'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '新增人数',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'curr_pnum'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '报单数量',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'order_num'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '商品数',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'order_product_num'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '报单金额（折扣前）',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '130',
          'prop': 'original_price'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '报单金额（折扣后）',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '130',
          'prop': 'order_money'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '余额支付金额',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'money1_price'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '积分支付金额',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'money4_price'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '实发奖金',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'curr_send_bonus'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '应发奖金',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'curr_all_bonus'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus0'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus1'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus2'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus3'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus4'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '120',
          'prop': 'bonus5'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus6'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus7'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus8'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus9'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus10'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus11'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus12'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus13'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'bonus14'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '扣复销',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'curr_deduct_resale'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '余额账户',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'money1'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '复销积分',
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'money4'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '充值金额',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'recharge_money'
        }, {
          'add': 0,
          'desc': '',
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '提现金额',
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': '100',
          'prop': 'take_money'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '后台调整金额（增）',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '130',
          'prop': 'op_plus_money'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '后台调整金额（减）',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '130',
          'prop': 'op_minus_money'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '运费',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'ex1'
        }, {
          'add': 0,
          'desc': '',
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '变动余额',
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': null,
          'prop': 'ex2'
        }, {
          'add': 0,
          'desc': '',
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '余额差',
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': null,
          'prop': 'ex3'
        }, {
          'add': 0,
          'desc': '',
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '变动积分',
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': null,
          'prop': 'ex4'
        }, {
          'add': 0,
          'desc': '',
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '积分差',
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': null,
          'prop': 'ex5'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '实体店订单数',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'ex6'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '实体商品数量',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'ex7'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '实体金额',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'ex8'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '网体盒数',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'ex9'
        }],
        'tableName': 't_total_report',
        'tableRadioProps': {},
        'tableSelectProps': {},
        'tableSwitchProps': {},
        'title': '系统总报表',
        'type': 'tableTemplate'
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 'add_time',
            value: []
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 'add_time',
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
    configBonusList({}).then(function _callee(res) {
      var bonus, keys, i, key, _i, item, isExit, j, bonusItem;

      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              bonus = res.rows;
              keys = [];

              for (i = 0; i < 15; i++) {
                key = 'bonus' + i;
                keys.push(key);
              }

              _i = 0;

            case 4:
              if (!(_i < _this2.tableModel_1001.tableModel.length)) {
                _context.next = 25;
                break;
              }

              item = _this2.tableModel_1001.tableModel[_i];

              if (!(keys.indexOf(item.prop) < 0)) {
                _context.next = 8;
                break;
              }

              return _context.abrupt("continue", 22);

            case 8:
              isExit = false;
              _context.t0 = regeneratorRuntime.keys(bonus);

            case 10:
              if ((_context.t1 = _context.t0()).done) {
                _context.next = 21;
                break;
              }

              j = _context.t1.value;
              bonusItem = bonus[j];

              if (!(item.prop == 'bonus' + bonusItem.tag)) {
                _context.next = 19;
                break;
              }

              item.label = bonusItem.name;
              item.show = 1;
              item.excel = 1;
              isExit = true;
              return _context.abrupt("break", 21);

            case 19:
              _context.next = 10;
              break;

            case 21:
              if (!isExit) {
                _this2.tableModel_1001.tableModel.splice(_i, 1);

                _i--;
              }

            case 22:
              _i++;
              _context.next = 4;
              break;

            case 25:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      systemReportList(params).then(function _callee2(res) {
        var i, key;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                for (i in res.rows) {
                  res.rows[i].bobi = (res.rows[i].bobi * 100).toFixed(2) + '%';

                  for (key in res.rows[i]) {
                    if (res.rows[i][key] == null) {
                      res.rows[i][key] = 0;
                    }
                  }
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this3 = this;

      //点击列表按钮回调
      this.$g.fun.startLoading('刷新中');
      refreshReport({
        key_date: row.key_date
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this3.$g.fun.endLoading();

                _this3.table.reloadData();

              case 2:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {
      //列表尾部合计回调
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });

        if (!values.every(function (value) {
          return isNaN(value);
        }) && index > 2) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2);
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};