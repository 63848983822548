import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.search";
import "core-js/modules/es.string.split";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import exportCustomExcel from '@/components/rw/plugin/exportCustomExcel/index';
import { expressCheckout, expressCheckoutAll, expressDeliver, expressDeliverAll, expressImport, expressLock, expressLockAll, expressReset, expressResetAll, orderAdd, orderProduct, productList } from '../../../../api/apis';
import NP from 'number-precision';
export default {
  name: 'orderOutStock',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出发货单",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "id": "",
          "tag": "export",
          "title": "导出列表",
          "type": "primary"
        }, {
          "icon": "el-icon-postcard",
          "id": "139",
          "tag": "export",
          "title": "导出财务",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "t_order.number",
              "value": ""
            }, {
              "desc": "收货人",
              "key": "t_order.real_name",
              "value": ""
            }, {
              "desc": "推荐人",
              "key": "t_order.direct",
              "value": ""
            }, {
              "desc": "收货电话",
              "key": "t_order.user_phone",
              "value": ""
            }, {
              "desc": "订单编号",
              "key": "t_order.order_id",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "订单金额",
              "key": "t_order.total_price"
            }],
            "operator": [{
              "key": "gte",
              "value": ">="
            }, {
              "key": "lte",
              "value": "<="
            }, {
              "key": "gt",
              "value": ">"
            }, {
              "key": "lt",
              "value": "<"
            }, {
              "key": "eq",
              "value": "="
            }, {
              "key": "ne",
              "value": "!="
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "商品名称",
              "key": "t_product.store_name"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "ex_number.phone",
              "desc": "会员手机"
            }],
            "operator": []
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "0"
            }, {
              "key": "t_order.paid",
              "value": "1"
            }],
            "title": "未出库",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "1"
            }, {
              "key": "t_order.paid",
              "value": "1"
            }],
            "title": "已出库",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "2"
            }, {
              "key": "t_order.paid",
              "value": "1"
            }],
            "title": "已发货",
            "total": 0,
            "type": "button"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "订单日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 8,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "支付日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_8"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 7,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "包含套餐",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_7"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 6,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "包含产品",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_6"]
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "119",
          "tag": "default",
          "title": "批量出库",
          "type": null
        }, {
          "id": "120",
          "tag": "default",
          "title": "批量发货",
          "type": null
        }, {
          "id": "121",
          "tag": "default",
          "title": "全部出库",
          "type": "primary"
        }, {
          "id": "122",
          "tag": "default",
          "title": "全部发货",
          "type": "primary"
        }, {
          "icon": "",
          "id": "158",
          "tag": "default",
          "title": "批量恢复状态",
          "type": "success"
        }],
        "id": "id_1001",
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "id"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "物流状态",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "status"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付日期",
          "rules": [],
          "show": 0,
          "type": "datetime",
          "width": "150",
          "prop": "t_order.pay_time"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "期数",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "70",
          "prop": "t_order.expect_num"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "order_id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "t_order.number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "报单人编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_order.store_number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "报单人姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_order.ex_store_number.real_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐全称",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "200",
          "prop": "ex_main_product.store_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐编码",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "ex_main_product.code"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品编码",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_product.code"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品名称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "t_product.store_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品简称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_product.shrink_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐价格",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "ex_main_product.price"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "价格",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "t_product.price"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "数量",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "quantity"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "小计",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_price"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货人",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_order.real_name"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货电话",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_order.user_phone"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "省",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "t_order.address_province"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "市",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "t_order.address_city"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "区",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "t_order.address_district"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货地址",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "150",
          "prop": "t_order.address_detail"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "出库时间",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "checkout_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单类型",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "select",
          "width": "100",
          "prop": "t_order.order_type"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单日期",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "t_order.add_time"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "t_order.pay_time"
        }],
        "tableName": "t_order",
        "tableRadioProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": 0,
              "value": "未出库"
            }, {
              "key": 1,
              "value": "已出库"
            }, {
              "key": 2,
              "value": "已发货"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": false
          },
          "t_order.order_type": {
            "data": [{
              "key": 1,
              "value": "注册单"
            }, {
              "key": 2,
              "value": "重购单"
            }, {
              "key": 3,
              "value": "升级单"
            }, {
              "key": 4,
              "value": "激活单"
            }, {
              "key": 5,
              "value": "店体自营"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "订单",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 't_order.add_time',
            value: []
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 't_order.add_time',
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_8: function modelConditionFunc_8(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 't_order.pay_time',
            value: []
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 't_order.pay_time',
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_6: function modelConditionFunc_6(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 0
            }
          }).then(function _callee(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'product_id',
                        value: item.id,
                        desc: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_7: function modelConditionFunc_7(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee2(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'main_product',
                        value: item.id,
                        desc: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
    this.curWhere = {};
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;

      if (!params.where.hasOwnProperty('is_lock')) {
        params.where.is_lock = 0;
      }

      this.curWhere = where;
      orderProduct(params).then(function _callee3(res) {
        var i, item;
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                for (i in res.rows) {
                  item = res.rows[i];
                  item.total_price = item.t_product.price * item.quantity;
                }

                _this2.testTable = res.rows;
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 3:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      orderAdd(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      var ids = row.map(function (item) {
        return item.id;
      });

      if ([0, 1, 4].indexOf(btnIndex) >= 0) {
        if (row.length <= 0) {
          this.$message({
            message: '请至少选择一个商品',
            type: 'warning'
          });
          return;
        }

        this.confirm('确定要将选中的' + ids.length + '件商品进行' + this.tableModel_1001.headerButton[btnIndex].title + '么?', function () {
          if (btnIndex === 0) {
            expressCheckout({
              id: ids
            }).then(function _callee5(res) {
              return regeneratorRuntime.async(function _callee5$(_context5) {
                while (1) {
                  switch (_context5.prev = _context5.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context5.stop();
                  }
                }
              });
            });
          } else if (btnIndex === 1) {
            expressDeliver({
              id: ids
            }).then(function _callee6(res) {
              return regeneratorRuntime.async(function _callee6$(_context6) {
                while (1) {
                  switch (_context6.prev = _context6.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context6.stop();
                  }
                }
              });
            });
          } // else if (btnIndex === 2) {
          //   let ids = row.map(item => item.order_id)
          //   expressLock({
          //     id: ids,
          //     is_lock: 1
          //   }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // } else if (btnIndex === 3) {
          //   let ids = row.map(item => item.order_id)
          //   expressLock({
          //     id: ids,
          //     is_lock: 0
          //   }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // }
          else if (btnIndex === 4) {
              var _ids = row.map(function (item) {
                return item.order_id;
              });

              expressReset({
                id: _ids
              }).then(function _callee7(res) {
                return regeneratorRuntime.async(function _callee7$(_context7) {
                  while (1) {
                    switch (_context7.prev = _context7.next) {
                      case 0:
                        _this3.$message({
                          message: '操作成功',
                          type: 'success'
                        });

                        _this3.table.reloadData();

                      case 2:
                      case "end":
                        return _context7.stop();
                    }
                  }
                });
              });
            }
        });
      } else {
        this.confirm('确定根据当前筛选条件进行' + this.tableModel_1001.headerButton[btnIndex].title + '么?', function () {
          var tmp = _this3.$tempModel.deepCopy(_this3.curWhere);

          delete tmp['t_order.paid'];

          if (btnIndex === 2) {
            expressCheckoutAll({
              where: tmp
            }).then(function _callee8(res) {
              return regeneratorRuntime.async(function _callee8$(_context8) {
                while (1) {
                  switch (_context8.prev = _context8.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context8.stop();
                  }
                }
              });
            });
          } else if (btnIndex === 3) {
            expressDeliverAll({
              where: tmp
            }).then(function _callee9(res) {
              return regeneratorRuntime.async(function _callee9$(_context9) {
                while (1) {
                  switch (_context9.prev = _context9.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context9.stop();
                  }
                }
              });
            });
          } // else if (btnIndex === 7) {
          //   expressLockAll({ where: tmp, is_lock: 1 }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // } else if (btnIndex === 8) {
          //   expressLockAll({ where: tmp, is_lock: 0 }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // }

        });
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    floatMul: function floatMul(arg1, arg2) {
      var m = 0,
          s1 = arg1.toString(),
          s2 = arg2.toString();

      try {
        m += s1.split(".")[1].length;
      } catch (e) {}

      try {
        m += s2.split(".")[1].length;
      } catch (e) {}

      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
    floatDiv: function floatDiv(arg1, arg2) {
      var t1 = 0,
          t2 = 0,
          r1,
          r2;

      try {
        t1 = arg1.toString().split(".")[1].length;
      } catch (e) {}

      try {
        t2 = arg2.toString().split(".")[1].length;
      } catch (e) {}

      r1 = Number(arg1.toString().replace(".", ""));
      r2 = Number(arg2.toString().replace(".", ""));
      return r1 / r2 * Math.pow(10, t2 - t1);
    },
    floatSub: function floatSub(arg1, arg2) {
      var r1, r2, m, n;

      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }

      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }

      m = Math.pow(10, Math.max(r1, r2)); //动态控制精度长度

      n = (r1 = r2) ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    exportFinance: function exportFinance(data) {
      var h1 = [];
      h1.push({
        label: '发货状态',
        prop: 'status1'
      });
      h1.push({
        label: '出库状态',
        prop: 'status2'
      });
      h1.push({
        label: '退换货状态',
        prop: ''
      });
      h1.push({
        label: '一级',
        prop: 'system_name'
      });
      h1.push({
        label: '二级',
        prop: ''
      });
      h1.push({
        label: '订单类型',
        prop: 't_order.order_type'
      });
      h1.push({
        label: '订单号',
        prop: 'order_id'
      });
      h1.push({
        label: '报单中心',
        prop: 't_order.store_number'
      });
      h1.push({
        label: '会员编号',
        prop: 't_order.number'
      });
      h1.push({
        label: '会员姓名',
        prop: 't_order.ex_number.real_name'
      });
      h1.push({
        label: '发货方式',
        prop: ''
      });
      h1.push({
        label: '运费承担方',
        prop: ''
      });
      h1.push({
        label: '收货人姓名',
        prop: 't_order.real_name'
      });
      h1.push({
        label: '收货人国家',
        prop: 'country'
      });
      h1.push({
        label: '省份',
        prop: 't_order.address_province'
      });
      h1.push({
        label: '城市',
        prop: 't_order.address_city'
      });
      h1.push({
        label: '县区',
        prop: 't_order.address_district'
      });
      h1.push({
        label: '收货地址',
        prop: 't_order.address_detail'
      });
      h1.push({
        label: '联系电话',
        prop: 't_order.user_phone'
      });
      h1.push({
        label: '支付日期',
        prop: 't_order.pay_time'
      });
      h1.push({
        label: '发货日期',
        prop: 'deliver_time'
      });
      h1.push({
        label: '产品类别',
        prop: 'ex_main_product.t_store_category.cate_name'
      });
      h1.push({
        label: '产品编码',
        prop: 't_product.code'
      });
      h1.push({
        label: '产品名称',
        prop: 't_product.store_name'
      });
      h1.push({
        label: '规格',
        prop: 't_product.unit_name'
      });
      h1.push({
        label: '单位',
        prop: 'unit_name'
      });
      h1.push({
        label: '数量',
        prop: 'quantity'
      });
      h1.push({
        label: '单价',
        prop: 't_product.price'
      });
      h1.push({
        label: '备注',
        prop: ''
      });
      h1.push({
        label: '套餐编码',
        prop: 'ex_main_product.code'
      });
      h1.push({
        label: '套餐名称',
        prop: 'ex_main_product.store_name'
      });
      h1.push({
        label: '零售价',
        prop: 'ex1'
      });
      h1.push({
        label: '零售额',
        prop: 'ex2'
      });
      h1.push({
        label: '订单金额',
        prop: 'ex3'
      });
      h1.push({
        label: '折扣率',
        prop: 'ex4'
      });
      h1.push({
        label: '加权平均单价',
        prop: 'ex5'
      });
      h1.push({
        label: '加权平均销售额',
        prop: 'ex6'
      });
      h1.push({
        label: '销售额验证',
        prop: 'ex7'
      });
      var tmp = {};

      for (var i in data) {
        var item = this.$tempModel.deepCopy(data[i]);

        if (item.status === '未出库') {
          item.status1 = '未发货';
          item.status2 = '未出库';
        } else if (item.status === '已出库') {
          item.status1 = '未发货';
          item.status2 = '已出库';
        } else if (item.status === '已发货') {
          item.status1 = '已发货';
          item.status2 = '已出库';
        }

        if (item['t_order.order_type'] == 5) {
          item['t_order.order_type'] = '店体自营';
        } else if (item['t_order.order_type'] == 7) {
          item['t_order.order_type'] = '店仓发货';
        } else if (item['t_order.order_type'] == 8) {
          item['t_order.order_type'] = '公司直发';
        } // if (item['t_order.order_type'] == 1){
        //   item['t_order.order_type'] = '注册单'
        // }else if (item['t_order.order_type'] == 2){
        //   item['t_order.order_type'] = '重购单'
        // }else if (item['t_order.order_type'] == 3){
        //   item['t_order.order_type'] = '升级单'
        // }else if (item['t_order.order_type'] == 4){
        //   item['t_order.order_type'] = '激活单'
        // }else{
        //   item['t_order.order_type'] = '未知'
        // }


        var key = item.t_order.order_id + item['t_product.id'];

        if (!tmp.hasOwnProperty(key)) {
          tmp[key] = {};
          tmp[key].data = item;
          tmp[key].productNum = 0;
        } else {}

        tmp[key].productNum += item.quantity;
        tmp[key].ex1 = item.price;
        tmp[key].ex2 = 0;
        tmp[key].ex3 = 0;
        tmp[key].ex4 = 0;
        tmp[key].ex5 = 0;
        tmp[key].ex6 = 0;
        tmp[key].ex7 = 0;
      }

      var outData = [];
      var tmpOrder = {};

      for (var _key in tmp) {
        var _item = tmp[_key];
        var orderKey = _item.data.t_order.order_id;

        if (!tmpOrder.hasOwnProperty(orderKey)) {
          tmpOrder[orderKey] = 0;
        }

        tmpOrder[orderKey] += _item.data.t_product.price * _item.productNum;
      }

      for (var _key2 in tmp) {
        var _item2 = tmp[_key2];
        _item2.data['system_name'] = 'XLS';
        _item2.data['quantity'] = tmp[_key2].productNum;
        _item2.data.ex1 = tmp[_key2].data.t_product.price; //零售价：系统自动带入产品零售价

        _item2.data.ex2 = _item2.data.ex1 * tmp[_key2].productNum; //零售额：计算公式等于零售价*单品发货数量

        _item2.data.ex3 = tmp[_key2].data.t_order.total_price; //订单金额：系统自动带入产品所对应订单编号内全部套餐的订单金额合计

        _item2.data.ex4 = _item2.data.ex3 == 0 || tmpOrder[_item2.data.t_order.order_id] == 0 ? 0 : NP.divide(_item2.data.ex3, tmpOrder[_item2.data.t_order.order_id]); //折扣率：计算公式等于订单金额/此订单编号包含的全部产品零售额合计

        _item2.data.ex5 = _item2.data.ex1 * _item2.data.ex4; //加权平均单价：计算公式等于零售价*折扣率

        _item2.data.ex6 = _item2.data.ex2 * _item2.data.ex4; //加权平均销售额：计算公式等于零售额*折扣率

        _item2.data.ex7 = this.floatSub(_item2.data.ex6 / tmp[_key2].productNum, _item2.data.ex5); //销售额验证：计算公式等于加权平均销售额/单品数量-加权平均单价，结果如为零则验证通过

        outData.push(_item2.data);
      }

      this.$export.excel({
        columns: h1,
        data: outData,
        title: '商品出库财务'
      }).then(function () {});
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      var _this4 = this;

      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        if (btnIndex === 2) {
          this.table.exportExcel();
          return;
        } else if (btnIndex === 3) {
          //导出财务
          this.table.exportExcelCustom(function (ret, isAll, columns, data) {
            _this4.exportFinance(data);
          });
          return;
        }

        this.table.exportExcelCustom(function (ret, isAll, columns, data) {
          if (!ret) {
            return;
          }

          console.log("data", data);
          var h1 = [];
          var h2 = [];
          var h3 = [];
          h1.push({
            label: 'ID',
            prop: 'id'
          });
          h1.push({
            label: '发货状态',
            prop: 'status1'
          });
          h1.push({
            label: '出库状态',
            prop: 'status2'
          });
          h1.push({
            label: '退换货状态',
            prop: ''
          });
          h1.push({
            label: '一级',
            prop: 'system_name'
          });
          h1.push({
            label: '二级',
            prop: ''
          });
          h1.push({
            label: '订单类型',
            prop: 't_order.order_type'
          });
          h1.push({
            label: '订单号',
            prop: 'order_id'
          });
          h1.push({
            label: '报单中心',
            prop: 't_order.store_number'
          });
          h1.push({
            label: '会员编号',
            prop: 't_order.number'
          });
          h1.push({
            label: '会员姓名',
            prop: 't_order.ex_number.real_name'
          });
          h1.push({
            label: '发货方式',
            prop: ''
          });
          h1.push({
            label: '运费承担方',
            prop: ''
          });
          h1.push({
            label: '收货人姓名',
            prop: 't_order.real_name'
          });
          h1.push({
            label: '收货人国家',
            prop: 'country'
          });
          h1.push({
            label: '省份',
            prop: 't_order.address_province'
          });
          h1.push({
            label: '城市',
            prop: 't_order.address_city'
          });
          h1.push({
            label: '县区',
            prop: 't_order.address_district'
          });
          h1.push({
            label: '收货地址',
            prop: 't_order.address_detail'
          });
          h1.push({
            label: '联系电话',
            prop: 't_order.user_phone'
          });
          h1.push({
            label: '支付日期',
            prop: 't_order.pay_time'
          });
          h1.push({
            label: '发货日期',
            prop: 'deliver_time'
          });
          h1.push({
            label: '套餐编码',
            prop: 'ex_main_product.code'
          });
          h1.push({
            label: '套餐名称',
            prop: 'ex_main_product.store_name'
          });
          h1.push({
            label: '产品类别',
            prop: 'ex_main_product.t_store_category.cate_name'
          });
          h1.push({
            label: '产品编码',
            prop: 't_product.code'
          });
          h1.push({
            label: '产品全称',
            prop: 't_product.store_name'
          });
          h1.push({
            label: '产品简称',
            prop: 't_product.shrink_name'
          });
          h1.push({
            label: '规格',
            prop: 't_product.unit_name'
          });
          h1.push({
            label: '单位',
            prop: 'unit_name'
          });
          h1.push({
            label: '数量',
            prop: 'quantity'
          });
          h1.push({
            label: '单价',
            prop: 't_product.price'
          });
          h1.push({
            label: '备注',
            prop: ''
          });
          h2.push({
            label: 'ID',
            prop: 'id'
          });
          h2.push({
            label: '支付日期',
            prop: 't_order.pay_time'
          });
          h2.push({
            label: '发货状态',
            prop: 'status1'
          });
          h2.push({
            label: '出库状态',
            prop: 'status2'
          });
          h2.push({
            label: '订单类型',
            prop: 't_order.order_type'
          });
          h2.push({
            label: '发货方式',
            prop: ''
          });
          h2.push({
            label: '运费承担方',
            prop: ''
          });
          h2.push({
            label: '订单号',
            prop: 'order_id'
          });
          h2.push({
            label: '收货人',
            prop: 't_order.real_name'
          });
          h2.push({
            label: '联系电话',
            prop: 't_order.user_phone'
          });
          h2.push({
            label: '收货地址',
            prop: 't_order.address'
          });
          h2.push({
            label: '套餐编码',
            prop: 'ex_main_product.code'
          });
          h2.push({
            label: '套餐名称',
            prop: 'ex_main_product.store_name'
          });
          h2.push({
            label: '产品编码',
            prop: 't_product.code'
          });
          h2.push({
            label: '产品全称',
            prop: 't_product.store_name'
          });
          h2.push({
            label: '产品简称',
            prop: 't_product.shrink_name'
          });
          h2.push({
            label: '套餐数量',
            prop: 'packageNum'
          });
          h2.push({
            label: '套餐单价',
            prop: 'ex_main_product.price'
          });
          h2.push({
            label: '产品总支数',
            prop: 'product_total_num'
          });
          h3.push({
            label: 'ID',
            prop: 'id'
          });
          h3.push({
            label: '支付日期',
            prop: 't_order.pay_time'
          });
          h3.push({
            label: '发货状态',
            prop: 'status1'
          });
          h3.push({
            label: '出库状态',
            prop: 'status2'
          });
          h3.push({
            label: '订单类型',
            prop: 't_order.order_type'
          });
          h3.push({
            label: '发货方式',
            prop: ''
          });
          h3.push({
            label: '运费承担方',
            prop: ''
          });
          h3.push({
            label: '订单号',
            prop: 'order_id'
          });
          h3.push({
            label: '收货人姓名',
            prop: 't_order.real_name'
          });
          h3.push({
            label: '联系电话',
            prop: 't_order.user_phone'
          });
          h3.push({
            label: '收货地址',
            prop: 't_order.address'
          });
          h3.push({
            label: '套餐编码',
            prop: 'ex_main_product.code'
          });
          h3.push({
            label: '套餐名称',
            prop: 'ex_main_product.store_name'
          });
          h3.push({
            label: '产品编码',
            prop: 't_product.code'
          });
          h3.push({
            label: '产品全称',
            prop: 't_product.store_name'
          });
          h3.push({
            label: '产品简称',
            prop: 't_product.shrink_name'
          });
          h3.push({
            label: '订单总价',
            prop: 't_order.total_price'
          });
          h3.push({
            label: '产品总支数',
            prop: 'product_total_num'
          });
          var dataList = [];
          var headerList = [h1, h2, h3];

          for (var i in data) {
            data[i].country = '中国';
          } //订单号、商品一样的（主赠品一样的）


          dataList[0] = [];
          dataList[1] = [];
          dataList[2] = [];
          var tmp = {};

          for (var _i in data) {
            var item = _this4.$tempModel.deepCopy(data[_i]);

            if (item.status === '未出库') {
              item.status1 = '未发货';
              item.status2 = '未出库';
            } else if (item.status === '已出库') {
              item.status1 = '未发货';
              item.status2 = '已出库';
            } else if (item.status === '已发货') {
              item.status1 = '已发货';
              item.status2 = '已出库';
            }

            if (item['t_order.order_type'] == 5) {
              item['t_order.order_type'] = '店体自营';
            } else if (item['t_order.order_type'] == 7) {
              item['t_order.order_type'] = '店仓发货';
            } else if (item['t_order.order_type'] == 8) {
              item['t_order.order_type'] = '公司直发';
            }

            console.log("t_order.order_type:", item['t_order.order_type']); // if (item['t_order.order_type'] == 1){
            //   item['t_order.order_type'] = '注册单'
            // }else if (item['t_order.order_type'] == 2){
            //   item['t_order.order_type'] = '重购单'
            // }else if (item['t_order.order_type'] == 3){
            //   item['t_order.order_type'] = '升级单'
            // }else if (item['t_order.order_type'] == 4){
            //   item['t_order.order_type'] = '激活单'
            // }else{
            //   item['t_order.order_type'] = '未知'
            // }

            var key = item.t_order.order_id + item['t_product.id'];

            if (!tmp.hasOwnProperty(key)) {
              tmp[key] = {};
              tmp[key].data = item;
              tmp[key].productNum = 0;
            } else {}

            tmp[key].productNum += item.quantity;
          }

          for (var _key3 in tmp) {
            var _item3 = tmp[_key3];
            _item3.data['system_name'] = 'XLS';
            _item3.data['quantity'] = tmp[_key3].productNum;
            dataList[0].push(_item3.data);
          }

          tmp = {}; //订单号与套餐唯一

          for (var _i2 in data) {
            var _item4 = _this4.$tempModel.deepCopy(data[_i2]);

            var _key4 = _item4.t_order.order_id + _item4['ex_main_product.code'];

            if (_item4.status === '未出库') {
              _item4.status1 = '未发货';
              _item4.status2 = '未出库';
            } else if (_item4.status === '已出库') {
              _item4.status1 = '未发货';
              _item4.status2 = '已出库';
            } else if (_item4.status === '已发货') {
              _item4.status1 = '已发货';
              _item4.status2 = '已出库';
            }

            if (_item4['t_order.order_type'] == 5) {
              _item4['t_order.order_type'] = '店体自营';
            } else if (_item4['t_order.order_type'] == 7) {
              _item4['t_order.order_type'] = '店仓发货';
            } else if (_item4['t_order.order_type'] == 8) {
              _item4['t_order.order_type'] = '公司直发';
            } // if (item['t_order.order_type'] == 1){
            //   item['t_order.order_type'] = '注册单'
            // }else if (item['t_order.order_type'] == 2){
            //   item['t_order.order_type'] = '重购单'
            // }else if (item['t_order.order_type'] == 3){
            //   item['t_order.order_type'] = '升级单'
            // }else if (item['t_order.order_type'] == 4){
            //   item['t_order.order_type'] = '激活单'
            // }else{
            //   item['t_order.order_type'] = '未知'
            // }


            if (!tmp.hasOwnProperty(_key4)) {
              tmp[_key4] = {};
              tmp[_key4].data = _item4;
              tmp[_key4].productNum = 0;
              tmp[_key4].product = {}; //产品名称

              tmp[_key4].productCode = {}; //产品编码

              tmp[_key4].productShort = {}; //产品简称
            }

            var packageNum = 0;

            for (var j in _item4.t_order_packages) {
              if (_item4.t_order_packages[j].package_product === _item4['ex_main_product.id']) {
                if (_item4.t_order_packages[j].is_main == 1) {
                  packageNum = 1;
                } else {
                  packageNum = _item4.t_order_packages[j].quantity;
                }

                break;
              }
            }

            tmp[_key4].data.packageNum = packageNum;

            if (!tmp[_key4].product.hasOwnProperty(_item4['t_product.store_name'])) {
              tmp[_key4].product[_item4['t_product.store_name']] = 0;
              tmp[_key4].productCode[_item4['t_product.code']] = 0;
              tmp[_key4].productShort[_item4['t_product.shrink_name']] = 0;
            }

            tmp[_key4].product[_item4['t_product.store_name']] += _item4.quantity;
            tmp[_key4].productCode[_item4['t_product.code']] += _item4.quantity;
            tmp[_key4].productShort[_item4['t_product.shrink_name']] += _item4.quantity;
          }

          for (var _key5 in tmp) {
            var _item5 = tmp[_key5];
            var name = '';
            var code = '';
            var short = '';
            var totalProductNum = 0;

            for (var productName in _item5.product) {
              name += productName + ' x ' + _item5.product[productName] / _item5.data.packageNum + ' , ';
              totalProductNum += _item5.product[productName] / _item5.data.packageNum;
            }

            for (var _productName in _item5.productCode) {
              code += _productName + ' x ' + _item5.productCode[_productName] / _item5.data.packageNum + ' , ';
            }

            for (var _productName2 in _item5.productShort) {
              if (_productName2 != 'null') {
                short += _productName2 + ' x ' + _item5.productShort[_productName2] / _item5.data.packageNum + ' , ';
              }
            }

            name = name.substr(0, name.length - 3);
            code = code.substr(0, code.length - 3);
            short = short.substr(0, short.length - 3);
            _item5.data['t_product.store_name'] = name;
            _item5.data['t_product.code'] = code;
            _item5.data['t_product.shrink_name'] = short;
            _item5.data['product_total_num'] = totalProductNum;
            _item5.data['t_order.address'] = _item5.data['t_order.address_province'] + _item5.data['t_order.address_city'] + _item5.data['t_order.address_district'] + _item5.data['t_order.address_detail'];
          }

          for (var _key6 in tmp) {
            dataList[1].push(tmp[_key6].data);
          } //订单号唯一


          tmp = {};

          for (var _i3 in data) {
            var _item6 = _this4.$tempModel.deepCopy(data[_i3]);

            if (_item6.status === '未出库') {
              _item6.status1 = '未发货';
              _item6.status2 = '未出库';
            } else if (_item6.status === '已出库') {
              _item6.status1 = '未发货';
              _item6.status2 = '已出库';
            } else if (_item6.status === '已发货') {
              _item6.status1 = '已发货';
              _item6.status2 = '已出库';
            }

            if (_item6['t_order.order_type'] == 5) {
              _item6['t_order.order_type'] = '店体自营';
            } else if (_item6['t_order.order_type'] == 7) {
              _item6['t_order.order_type'] = '店仓发货';
            } else if (_item6['t_order.order_type'] == 8) {
              _item6['t_order.order_type'] = '公司直发';
            } // if (item['t_order.order_type'] == 1){
            //   item['t_order.order_type'] = '注册单'
            // }else if (item['t_order.order_type'] == 2){
            //   item['t_order.order_type'] = '重购单'
            // }else if (item['t_order.order_type'] == 3){
            //   item['t_order.order_type'] = '升级单'
            // }else if (item['t_order.order_type'] == 4){
            //   item['t_order.order_type'] = '激活单'
            // }else{
            //   item['t_order.order_type'] = '未知'
            // }


            var _packageNum = 0;

            for (var _j in _item6.t_order_packages) {
              if (_item6.t_order_packages[_j].package_product === _item6['ex_main_product.id']) {
                if (_item6.t_order_packages[_j].is_main == 1) {
                  _packageNum = 1;
                } else {
                  _packageNum = _item6.t_order_packages[_j].quantity;
                }

                break;
              }
            }

            var _key7 = _item6.t_order.order_id;

            if (tmp.hasOwnProperty(_key7)) {
              if (tmp[_key7].packageName.hasOwnProperty(_item6['ex_main_product.store_name'])) {
                tmp[_key7].packageName[_item6['ex_main_product.store_name']] = _packageNum;
              } else {
                tmp[_key7].packageName[_item6['ex_main_product.store_name']] = _packageNum;
              }

              if (tmp[_key7].packageCode.hasOwnProperty(_item6['ex_main_product.code'])) {
                tmp[_key7].packageCode[_item6['ex_main_product.code']]++;
              } else {
                tmp[_key7].packageCode[_item6['ex_main_product.code']] = 1;
              }

              if (tmp[_key7].productCode.hasOwnProperty(_item6['t_product.code'])) {
                tmp[_key7].productCode[_item6['t_product.code']] += _item6.quantity;
              } else {
                tmp[_key7].productCode[_item6['t_product.code']] = _item6.quantity;
              }

              if (tmp[_key7].productName.hasOwnProperty(_item6['t_product.store_name'])) {
                tmp[_key7].productName[_item6['t_product.store_name']] += _item6.quantity;
              } else {
                tmp[_key7].productName[_item6['t_product.store_name']] = _item6.quantity;
              }

              if (tmp[_key7].productSort.hasOwnProperty(_item6['t_product.shrink_name'])) {
                tmp[_key7].productSort[_item6['t_product.shrink_name']] += _item6.quantity;
              } else {
                tmp[_key7].productSort[_item6['t_product.shrink_name']] = _item6.quantity;
              }
            } else {
              tmp[_key7] = {};
              tmp[_key7].data = _item6;
              tmp[_key7].packageName = {};
              tmp[_key7].packageName[_item6['ex_main_product.store_name']] = _packageNum;
              tmp[_key7].packageCode = {};
              tmp[_key7].packageCode[_item6['ex_main_product.code']] = _packageNum;
              tmp[_key7].productCode = {};
              tmp[_key7].productCode[_item6['t_product.code']] = _item6.quantity;
              tmp[_key7].productName = {};
              tmp[_key7].productName[_item6['t_product.store_name']] = _item6.quantity;
              tmp[_key7].productSort = {};
              tmp[_key7].productSort[_item6['t_product.shrink_name']] = _item6.quantity;
            }
          }

          for (var _key8 in tmp) {
            var _item7 = tmp[_key8].data;
            var tk = tmp[_key8];
            var packageName = '';

            for (var subKey in tk.packageName) {
              packageName += subKey + ' x ' + tk.packageName[subKey] + ' , '; // packageName += subKey+' , '
            }

            packageName = packageName.substr(0, packageName.length - 2);
            var packageCode = '';

            for (var _subKey in tk.packageCode) {
              packageCode += _subKey + ' ; '; // packageCode += subKey+' , '
            }

            packageCode = packageCode.substr(0, packageCode.length - 2);
            var productCode = '';

            for (var _subKey2 in tk.productCode) {
              productCode += _subKey2 + ' x ' + tk.productCode[_subKey2] + ' , ';
            }

            productCode = productCode.substr(0, productCode.length - 2);
            var _totalProductNum = 0;
            var _productName3 = '';

            for (var _subKey3 in tk.productName) {
              _productName3 += _subKey3 + ' x ' + tk.productName[_subKey3] + ' , ';
              _totalProductNum += tk.productName[_subKey3];
            }

            _productName3 = _productName3.substr(0, _productName3.length - 2);
            var productSort = '';

            for (var _subKey4 in tk.productSort) {
              productSort += _subKey4 + ' x ' + tk.productSort[_subKey4] + ' , ';
            }

            productSort = productSort.substr(0, productSort.length - 2);
            _item7['ex_main_product.store_name'] = packageName;
            _item7['ex_main_product.code'] = packageCode;
            _item7['t_product.code'] = productCode;
            _item7['t_product.store_name'] = _productName3;
            _item7['t_product.shrink_name'] = productSort;
            _item7['product_total_num'] = _totalProductNum;
            _item7['t_order.address'] = _item7['t_order.address_province'] + _item7['t_order.address_city'] + _item7['t_order.address_district'] + _item7['t_order.address_detail'];
            dataList[2].push(_item7);
          }

          console.log('cccc', dataList[1]);
          exportCustomExcel.excelMulSheet({
            title: '商品出库列表',
            columns: headerList,
            data: dataList,
            sheets: ['明细表', '套餐表', '订单表']
          });
        });
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    confirm: function confirm(msg, handle) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (handle) {
          handle();
        }
      }).catch(function () {});
    }
  }
};